const Layout = () => import("views/layout/Layout.vue");
const _import = path => import(`views/admin/${path}`)
export const adminRoutes = [
  {
    path: '/institution',
    name: 'Institution',
    component: Layout,
    redirect: '/institution/list',
    children: [
      {
        path: "/institution/list",
        name: "InstitutionList",
        component: () => _import("institution/list.vue"),
        meta: {
          name_str: "机构管理"
        }
      },
      {
        path: "/institution/mode",
        name: "checklist",
        component: () => _import("checklist/mode.vue"),
        meta: {
          name_str: "检查表模板"
        }
      },
    ]
  },
]
